<template>
  <v-container>
    <BoxTitle :title="'¡Bienvenida, emprendedora!'">
      <template v-slot:content>
        <p>Este formulario es el <b>primer paso para conseguir su crédito</b> y tomar las riendas de las finanzas de su negocio.</p>
        <p>
          El formulario tiene varias partes. <b>Recuerde intentar responder a todas las preguntas que pueda</b>, ya que cuanto más sepamos
          sobre sus ambiciones, su hogar y su negocio, más rápido le podremos proporcionar un crédito adecuado para usted.
        </p>
        <p>¡Vamos allá!</p>
      </template>
    </BoxTitle>
    <div class="text-center">
      <v-btn class="mr-3 normal-button" rounded @click="(dialogIdentityDocument = true), (consultRequest = true)">
        <span class="font-weight-bold">CONSULTAR MI SOLICITUD</span>
      </v-btn>
      <v-btn class="mt-5 mt-md-0 primary normal-button" rounded @click="(dialogIdentityDocument = true), (newRequest = true)">
        <span class="font-weight-bold">EMPEZAR NUEVA SOLICITUD</span>
      </v-btn>
    </div>

    <!-- Dialog enter cedula -->
    <v-dialog v-model="dialogIdentityDocument" transition="dialog-top-transition" max-width="400" @click:outside="resetActions">
      <v-card class="text-center">
        <v-form ref="formIdentityDocument" v-model="formIdentityDocumentValid" lazy-validation @submit.prevent="">
          <v-toolbar color="primary">
            <span>Documento de identidad</span>
            <v-spacer />
            <v-icon @click="(dialogIdentityDocument = false), resetActions()">clear</v-icon>
          </v-toolbar>
          <v-card-text class="pa-5">
            <v-text-field
              v-model="numberIdentityDocument"
              :rules="formRules.requiredField"
              label="Cédula"
              @keydown.enter="checkBorrowerExists()"
            />
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="checkBorrowerExists()">Continuar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Dialog identity document not found -->
    <v-dialog v-model="dialogIdentityDocumentNotFound" transition="dialog-top-transition" max-width="400">
      <v-card class="text-center pa-5">
        <span>El documento de identidad introducido no existe en nestro sistema, por favor empiece una nueva solicitud</span>
      </v-card>
    </v-dialog>

    <!-- Dialog create credit with warning -->
    <v-dialog v-model="dialogCreateCreditWithWarning" transition="dialog-top-transition" max-width="400">
      <v-card class="text-center pa-5">
        <span>
          Haz click en continuar si quieres iniciar una nueva solicitud de crédito, pero ten en cuenta que para recibirlo, debe terminar de
          cancelar el actual
        </span>
        <div class="text-center">
          <v-btn class="mt-5 mt-md-0 primary normal-button" rounded @click="$router.push('/select-office')">
            <span class="font-weight-bold">CONTINUAR</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <!-- Dialog can not create a new credit -->
    <v-dialog v-model="isNotAllowedCreateCredit" transition="dialog-top-transition" max-width="400">
      <v-card class="text-center pa-5">
        <span>{{ notAllowedCreateCreditMessage }}</span>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import BoxTitle from "@/components/common/BoxTitle.vue";

export default {
  components: { BoxTitle },
  name: "Home",
  mixins: [FormRulesMixin, FormatDateMixin],
  data() {
    return {
      numberIdentityDocument: "",
      dialogIdentityDocument: false,
      formIdentityDocumentValid: false,
      dialogIdentityDocumentNotFound: false,
      dialogCreateCreditWithWarning: false,

      // Actions
      consultRequest: false,
      newRequest: false
    };
  },
  computed: {
    ...mapState("borrower", ["borrower_data", "creditHistory"]),
    isNotAllowedCreateCredit: {
      get() {
        return this.creditHistory.filter((e) => !e.allowed).length > 0;
      },
      set(value) {
        return value;
      }
    },
    notAllowedCreateCreditMessage() {
      const requests = this.creditHistory.filter((e) => e.type === "request");
      const requestsSortedByDate = requests.sort((a, b) => (a.created_at < b.created_at ? -1 : a.created_at > b.created_at ? 1 : 0));
      const request = requestsSortedByDate[requestsSortedByDate.length - 1];
      let message = "";

      if (request) {
        const project = this.creditHistory.find((e) => e.request_id === request.id);

        switch (request.code) {
          case "request-active":
            message =
              "Tiene una solicitud de crédito en curso. Haga click en “consultar estado de mi solicitud” para conocer más detalles sobre el estado";
            break;
          case "request-approved":
            switch (project.code) {
              case "project-not-active":
                message =
                  "Tiene una solicitud de crédito en curso. Haga click en “consultar estado de mi solicitud” para conocer más detalles sobre el estado";
                break;
              case "project-active":
              case "project-defendant":
                message = `Tiene un crédito en curso con MICROWD, desembolsado el ${this.formatDateL(
                  project.active_at
                )}. Consulte con su oficina o asesor si requiere más detalles`;
                break;

              default:
                break;
            }
            break;
          case "project-with-delays":
            message = "Sentimos las molestias. Para continuar el proceso de solicitud del crédito debe contactar a su oficina o asesor";
            break;

          default:
            message = "Error: Actualmente no puede crear una nueva solicitud, para más información póngase en contacto con MICROWD";
            break;
        }
      }

      return message;
    },
    allowedCreateCreditWithWarning() {
      const requestsAllowed = this.creditHistory.filter((e) => e.allowed && e.type === "request");

      for (let i = 0; i < requestsAllowed.length; i++) {
        const request = this.creditHistory[i];

        if (request.code === "request-approved") {
          const project = this.creditHistory.find((e) => e.request_id === request.id);
          return project.code === "project-active";
        }
      }

      return false;
    }
  },
  methods: {
    async checkBorrowerExists() {
      if (this.$refs.formIdentityDocument.validate()) {
        await this.$store.dispatch("borrower/checkBorrowerExists", this.numberIdentityDocument);

        if (this.consultRequest) {
          if (!this.borrower_data.identity_document) {
            this.dialogIdentityDocumentNotFound = true;
          } else {
            this.$router.push("/requests");
          }
        }

        if (this.newRequest) {
          if (!this.borrower_data.identity_document) {
            this.$store.commit("borrower/SET_NEW_BORROWER_IDENTITY_DOCUMENT", this.numberIdentityDocument);
            this.$router.push("/new-borrower");
          } else if (this.allowedCreateCreditWithWarning && !this.isNotAllowedCreateCredit) this.dialogCreateCreditWithWarning = true;
          else if (!this.isNotAllowedCreateCredit) this.$router.push("/select-office");
        }
      }
    },
    resetActions() {
      this.consultRequest = false;
      this.newRequest = false;
    }
  }
};
</script>
